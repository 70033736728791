/* eslint-disable no-useless-escape */
import { mapActions, mapGetters } from 'vuex'
import { isNotEmptyError } from '@/utils'
const CONFIG = {
  name: '"Nama"',
  email: '"Email"',
  nohp: '"Nomor Telepon"',
  voucherCode: '"Kode Voucher"',
  customerId: '"Lembaga Pelatihan"',
  ecommerce: '"Platform Digital"',
  redeemCode: '"Kode Redeem"'
}
export default {
  components: {
    Modal: () => import(/* webpackChunkName: "modal" */ '@/components/modal/Modal'),
    Chevron: () => import(/* webpackChunkName: "icons" */ '@/components/icons/Chevron'),
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button'),
    TextField: () => import(/* webpackChunkName: "textfield" */ '@/components/forms/TextField'),
    Dropdown: () => import(/* webpackChunkName: "DropDown" */ '@/components/dropdown/Dropdown')
  },
  props: {
    selectedDP: {
      type: String,
      default: ''
    },
    selectedLP: {
      type: String,
      default: ''
    },
    educationID: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    payloads: {
      voucherCode: '',
      ecommerce: '',
      redeemCode: ''
    },
    redemptionCode: '',
    errors: [],
    referralCode: '',
    notification: null,
    isRegisterSuccess: false,
    params: '',
    classTittle: '',
    isRedeeming: false,
    redeemError: '',
    customerId: '', // 0 -> G2Academy, 1 -> Accelerice,
    isReadyToSubmit: false,
    selectedCustomer: null,
    digitalPlatformDropdown: null,
    selectedDigitalPlatform: null,
    isSinglePageDp: false,
    errorLog: {
      name: '',
      email: '',
      nohp: '',
      redeemCode: '',
      voucherCode: ''
    }
  }),
  mounted() {
    this.params = this.$route.params.referralcode
    this.getCodeReferral()
    if (this.isLoggedIn && this.userData.role_id !== 'STUDENT') {
      this.getUser()
    }
    this.getData()
  },
  computed: {
    ...mapGetters('auth', ['userData', 'isLoggedIn']),
    ...mapGetters('data', ['educationalInstitutions', 'digitalPlatforms']),
    ...mapGetters('prakerja', ['isContactUsVisible']),
    userId() {
      return localStorage.getItem('user_id')
    },
    selectedName() {
      return this.payloads.name
    },
    selectedEmail() {
      return this.payloads.email
    },
    selectedPhone() {
      return this.payloads.nohp
    },
    selectedVoucher() {
      return this.payloads.voucherCode
    },
    selectedRedeemCode() {
      return this.payloads.redeemCode
    },
    isPayloadNotValid() {
      if (Object.values(this.payloads).includes('')) return true
      if (Object.values(this.errorLog).filter((val) => val === true).length > 0) return true
      return false
    },
    isPayloadError() {
      return Object.values(this.errorLog).filter((val) => val === true).length > 0
    },
    slugDp() {
      return this.$route.params.slugDp ? this.$route.params.slugDp : ''
    }
  },
  watch: {
    selectedRedeemCode(val) {
      if (val === '') {
        this.errorLog.redeemCode = true
        this.errors['redeemCode'] = 'Kode Redeem tidak boleh kosong'
        return
      }
      this.errorLog.redeemCode = false
      this.errors['redeemCode'] = ''
    },
    selectedVoucher(val) {
      if (val === '') {
        this.errorLog.voucherCode = true
        this.errors['voucherCode'] = 'Kode Voucher tidak boleh kosong'
        return
      }
      this.errorLog.voucherCode = false
      this.errors['voucherCode'] = ''
    },
    digitalPlatformDropdown(val) {
      if (val) {
        this.selectedDigitalPlatform = val
        this.payloads.ecommerce = val.code
      }
    },
    educationalInstitutions(lpList) {
      if (lpList) {
        const autoFillLp = lpList.filter((val) => val.initial === this.selectedLP)[0]
        this.selectedCustomer = autoFillLp || null
      }
    }
  },
  methods: {
    ...mapActions('common', ['setThankYouPage', 'showLoading', 'hideLoading']),
    ...mapActions('prakerja', ['getUserProfile', 'redeemCode', 'setContactUsVisible']),
    ...mapActions('data', ['getDigitalPlatforms', 'getEducationalInstitutions']),
    getCodeReferral() {
      if (this.params) {
        this.referralCode = this.params === 'redeem' ? '' : this.params
      } else {
        this.referralCode = this.userId
      }
    },
    getEducationalInstitutionsName(customerId) {
      return this.educationalInstitutions.filter((item) => item.code === customerId)[0]?.name
    },
    getUser() {
      this.getUserProfile().then((res) => {
        this.email = res.email
        this.name = res.name
        this.nohp = res.hp
      })
    },
    async getData() {
      if (this.slugDp.length > 0) {
        this.isSinglePageDp = true
      }
      this.showLoading()
      await this.getDigitalPlatforms({
        slug: this.slugDp
      })
      this.hideLoading()
      if (this.slugDp.length > 0) {
        if (this.digitalPlatforms.length > 0) {
          this.selectedDigitalPlatform = this.digitalPlatforms[0]
          this.payloads.ecommerce = this.digitalPlatforms[0].code
        } else {
          this.$router.push('/prakerja/redeem')
        }
      }
    },
    validForm() {
      const error = isNotEmptyError(this.payloads)
      this.errors = error.reduce(function (map, obj) {
        map[obj.field] = CONFIG[obj.field] + ' ' + obj.error
        return map
      }, {})
      return Object.keys(this.errors).length === 0
    },
    goNext() {
      this.isReadyToSubmit = true
    },
    goBack() {
      this.isReadyToSubmit = false
      this.redeemError = ''
    },
    doRedeemCode() {
      this.redeemError = ''
      this.isRedeeming = true
      this.showLoading()
      const payloads = {
        nama: '',
        email: '',
        hp: '',
        provinsi: '',
        kode: this.payloads.voucherCode,
        referral_code: '',
        ecommerce: this.payloads.ecommerce,
        customer_id: this.educationID,
        redemptionCode: this.payloads.redeemCode,
        scope: 'redeem',
        activityId: 0,
        activityItemId: 0
      }
      this.redeemCode({
        payloads
      }).then(
        (response) => {
          this.hideLoading()
          const redeemStatus = response.data.flag
          const message = response.message
          if (!redeemStatus && message && message.toLowerCase() === 'success') {
            // this.setThankYouPage({
            //   data: { title: response.data.kelas, redirect: 'home' }
            // })
            this.$emit('redeemSuccess')
          } else {
            if (redeemStatus && redeemStatus.toLowerCase() === 'rejected') {
              this.redeemError = 'Kode voucher yang Anda masukkan salah! Silakan coba lagi dengan kode voucher yang sesuai.'
            } else if (redeemStatus && redeemStatus.toLowerCase() === 'duplicated') {
              this.redeemError = 'Kode voucher yang Anda masukkan sudah pernah digunakan oleh akun yang lain!'
            } else if (redeemStatus && redeemStatus.toLowerCase() === 're send') {
              window.location.replace(response.data.redirect_url)
            }
          }
          setTimeout(() => {
            this.isRedeeming = false
          }, 1000)
        },
        (error) => {
          this.hideLoading()
          this.isRedeeming = false
          if (error.response.status === 400) {
            return (this.redeemError = error?.response?.data?.errors?.error || 'Kode redeem yang Anda masukkan sudah pernah digunakan!')
          }
          if (error.response.status === 422) {
            return (this.redeemError = error?.response?.data?.errors?.error || 'Anda sudah mempunyai kelas dengan kode voucher tersebut, silakan cek profil!')
          }
          this.redeemError = 'Kode voucher yang Anda masukkan tidak valid, silakan hubungi admin!'
        }
      )
    },
    toggleContactUsModal() {
      this.setContactUsVisible(!this.isContactUsVisible)
    }
  }
}
